<template>
    <div class="change-password">
        <button type="button" class="btn-block-option close-modal" @click="$modal.hide(modalName)"> ×
        </button>
        <div class="change-password__body">
            <h3 class="change-password__title">Смена пароля</h3>
            <ValidationObserver tag="form" ref="observer" @submit.prevent="onSubmit" class="change-password__form">
                <ValidationProvider tag="div" :rules="`required|min_length:8`" class="change-password__group change-password__group--old" vid="old_password" v-slot="{errors}">
                    <label for="id_old" class="change-password__name">Введите текущий пароль</label>
                    <input v-model="form.old_password" type="password" class="change-password__input" id="id_old">
                    <span class="v-error animated fadeInDown" v-if="errors.length">
                        {{errors[0]}}
                    </span>
                </ValidationProvider>
                <ValidationProvider tag="div" :rules="`required|min_length:8`" class="change-password__group change-password__group--new" vid="new_password" v-slot="{errors}">
                    <label for="id_new" class="change-password__name">Новый пароль</label>
                    <input v-model="form.new_password" type="password" class="change-password__input" id="id_new">
                    <span class="v-error animated fadeInDown" v-if="errors.length">
                        {{errors[0]}}
                    </span>
                </ValidationProvider>
                <ValidationProvider tag="div" :rules="`required|min_length:8`" class="change-password__group change-password__group--confirm" vid="confirm_password" v-slot="{errors}">
                    <label for="id_confirm" class="change-password__name">Подтвердите новый пароль</label>
                    <input v-model="form.confirm_password" type="password" class="change-password__input" id="id_confirm">
                    <span class="v-error animated fadeInDown" v-if="errors.length">
                        {{errors[0]}}
                    </span>
                </ValidationProvider>
                <div class="change-password__btns">
                    <button type="submit" class="v-btn v-btn--gray" :disabled="isSending">Сменить пароль</button>
                    <button type="button" class="v-btn v-btn--transparent" @click="$modal.hide(modalName)">Отменить</button>
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: "ChangePassword",
        props: {
            user_id: {
                type: Number,
                required: true
            },
        },
        data() {
          return {
              modalName: 'ChangePassword',
              isSending: false,
              form: {
                  old_password: '',
                  new_password: '',
                  confirm_password: ''
              }
          }
        },
        methods: {
            async onSubmit() {
                let isValid = await this.$refs.observer.validate();
                if (isValid) {
                    try {
                        this.isSending = true;
                        const request = await session.post(`/api/v1/profile/${this.user_id}/change_password/`, this.form);
                        this.$swal({
                            title: 'Отлично!',
                            text: 'Данные изменены',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1400,
                        });
                        this.$modal.hide(this.modalName);
                    } catch(error) {
                        if (error.response) {
                            this.$refs.observer.setErrors(error.response.data);
                        }
                    }
                    this.isSending = false
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    .change-password {
        padding: 30px;
        &__form {
            display: flex;
            flex-direction: column;
            margin-top: 25px;
        }
        &__group {
            display: flex;
            flex-direction: column;
            /*&--new {
                margin-top: 30px;
            }*/
            &--confirm {
                margin-top: 15px;
            }
        }
        &__name {
            font-size: 1rem;
            font-weight: 300;
            color: $text-gray-2;
        }
        &__input {
            border: 1px solid $border-gray;
            height: 40px;
            padding: 0 10px;
            &:focus {
                outline: 1px solid $gold;
                border: 1px solid $gold;
            }
        }
        &__btns {
            margin-top: 35px;
        }
    }
</style>
